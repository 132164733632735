import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { PLATFORM_DISPLAY_NAME } from 'constants/common';
import navRoutes from 'navigation/Routes';

import {
  helpUrl,
  blogUrl,
  aboutUrl,
  pricingUrl,
  affiliatesUrl,
  contactUrl,
  meetingUrl,
  wavelengthUrl,
  trialUrl,
  solutionUrls,
} from 'constants/env';

import { hooks } from 'utils';

import {
  Flex,
  Stack,
  Text,
  Divider,
  MdIcon,
  Box,
  Link as ExternalLink,
  LinkButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useColorModeValue,
} from '@workshop/ui';
import AnimatedDropDown from 'components/Common/AnimatedDropDown';

import { PaymentsWelcomeModal } from 'screens/cms/MyOrganisation/src';

interface Props {
  onBg?: boolean;
}

const EXTENDED_WIDTH = 300;

const JoinItem: React.FC<{
  label: string;
  icon?: string;
  onClick?: () => void;
  isActive?: boolean;
  dropdown?: {
    label: string;
    icon: string;
    link: string;
  }[];
}> = ({ label, icon, onClick = () => null, isActive, dropdown }) => {
  const currentRoute = hooks.useCurrentRoute();
  const bg = useColorModeValue('background.default', {
    base: 'background.default',
    md: 'background.tint3',
  });
  const openBg = useColorModeValue('background.tint3', {
    base: 'background.tint3',
    md: 'background.default',
  });
  if (dropdown) {
    return (
      <Menu>
        {({ isOpen }) => (
          <>
            <MenuButton
              _hover={{ bg: openBg }}
              borderRadius={{ base: 'none', md: 'full' }}
              my={{ base: 0, md: 1 }}
              cursor="pointer"
              w="100%"
              bg={isOpen ? openBg : bg}
            >
              <Flex
                alignItems="center"
                pl="defaultPadding"
                pr={1.5}
                py={{ base: 'defaultPadding', md: 1 }}
                color={{
                  base: isActive ? 'text.primary' : 'text.default',
                  md: isActive ? 'text.primary' : 'text.muted',
                }}
              >
                {icon ? <MdIcon name={icon} mr={2} /> : null}
                <Text fontSize="sm" fontWeight="semibold">
                  {label}
                </Text>
                <Flex flex={1} />
                <MdIcon
                  name={isOpen ? 'ExpandLess' : 'ExpandMore'}
                  ml={2}
                  mr={{ base: 2, md: 0 }}
                />
              </Flex>
            </MenuButton>
            <MenuList w={{ base: '100vw', md: 'auto' }}>
              {dropdown.map((l) => {
                const isItemActive = currentRoute?.path() === l.link;
                return (
                  <MenuItem
                    as={ExternalLink}
                    _hover={{ textDecoration: 'none' }}
                    href={l.link}
                    color={{
                      base: isItemActive ? 'text.primary' : 'text.default',
                      md: isItemActive ? 'text.primary' : 'text.muted',
                    }}
                    icon={<MdIcon name={l.icon} fontSize="md" />}
                    key={`dropdown-nav-${l.label}`}
                  >
                    <Text fontSize="sm" fontWeight="semibold" py={1}>
                      {l.label}
                    </Text>
                  </MenuItem>
                );
              })}
            </MenuList>
          </>
        )}
      </Menu>
    );
  }
  return (
    <Flex
      alignItems="center"
      px="defaultPadding"
      py={{ base: 'defaultPadding', md: 1 }}
      my={{ base: 0, md: 1 }}
      cursor="pointer"
      _hover={{ bg: 'background.tint3' }}
      onClick={onClick}
      color={{
        base: isActive ? 'text.primary' : 'text.default',
        md: isActive ? 'text.primary' : 'text.muted',
      }}
      borderRadius={{ base: 'none', md: 'full' }}
    >
      {icon ? <MdIcon name={icon} mr={2} /> : null}
      <Text fontSize="sm" fontWeight="semibold">
        {label}
      </Text>
    </Flex>
  );
};

const JoinButton: React.FC<Props> = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleBtnClick = () => setIsOpen(!isOpen);
  const currentRoute = hooks.useCurrentRoute();

  const NAV_LINKS = [
    {
      label: 'Solutions',
      icon: 'Lightbulb',
      dropdown: [
        {
          label: 'For Teachers, Tutors & Professors',
          icon: 'School',
          link: solutionUrls.teachers,
        },
        {
          label: 'For Independent Learning Providers',
          icon: 'Store',
          link: solutionUrls.providers,
        },
        {
          label: 'For Education Specialists',
          icon: 'SupervisorAccount',
          link: solutionUrls.specialists,
        },
        {
          label: 'For Coaches',
          icon: 'Psychology',
          link: solutionUrls.coaches,
        },
        // {
        //   label: 'For Content Creators',
        //   icon: 'MovieCreation',
        //   link: solutionUrls.creators,
        // },
        // {
        //   label: 'For Brands',
        //   icon: 'Category',
        //   link: solutionUrls.brands,
        // },
        // {
        //   label: 'For Internal Training',
        //   icon: 'Badge',
        //   link: solutionUrls.training,
        // },
      ],
    },
    {
      label: 'Resources',
      icon: 'Description',
      dropdown: [
        {
          label: 'About Us',
          icon: 'Info',
          link: aboutUrl,
        },
        // {
        //   label: 'Blog',
        //   icon: 'LibraryBooks',
        //   link: blogUrl,
        // },
        // {
        //   label: 'Guides',
        //   icon: 'Checklist',
        //   link: '/resources/guides',
        // },
        {
          label: 'Help & Support',
          icon: 'Help',
          link: helpUrl,
        },
        {
          label: 'Pricing',
          icon: 'Payments',
          link: pricingUrl,
          external: true,
        },
        {
          label: 'Affiliate Program',
          icon: 'Share',
          link: affiliatesUrl,
        },
        {
          label: 'Contact Us',
          icon: 'QuestionAnswer',
          link: contactUrl,
        },
        {
          label: 'Book an Intro',
          icon: 'Event',
          link: meetingUrl,
        },
      ],
    },
    {
      label: 'Wavelength',
      icon: 'Waves',
      link: wavelengthUrl,
      external: true,
    },
    {
      label: 'Log In',
      icon: 'Login',
      link: navRoutes.public.login.path(),
    },
  ];

  return (
    <Flex>
      <Stack
        direction="row"
        display={{ base: 'none', md: 'flex' }}
        alignItems="center"
      >
        {NAV_LINKS.map((l) => {
          if (l.dropdown) {
            return (
              <Flex key={`main-nav-${l.label}`}>
                <JoinItem
                  label={l.label}
                  isActive={currentRoute?.path() === l.link}
                  dropdown={l.dropdown}
                />
              </Flex>
            );
          }
          return (
            // @ts-ignore
            <Box
              {...(l.external
                ? {
                    href: l.link,
                    // @ts-ignore
                    as: ExternalLink,
                    _hover: { textDecoration: 'none' },
                  }
                : {
                    to: l.link || '#',
                    // @ts-ignore
                    as: Link,
                  })}
              key={`main-nav-${l.label}`}
            >
              <JoinItem
                label={l.label}
                // @ts-ignore
                isActive={currentRoute?.path() === l.link}
                dropdown={l.dropdown}
                // {...(l.onClick ? { onClick: l.onClick } : {})}
              />
            </Box>
          );
        })}
        <LinkButton size="sm" href={trialUrl}>
          Get a Free Trial
        </LinkButton>
        {/* {currentRoute?.path() !== navRoutes.global.planner.path() ? (
          <LinkButton size="sm" to={navRoutes.global.planner.path()}>
            Get a Free Trial
          </LinkButton>
        ) : null} */}
      </Stack>
      <Flex display={{ base: 'flex', md: 'none' }}>
        <AnimatedDropDown
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          onClick={handleBtnClick}
          onBg={props.onBg}
          title=""
          minimisedWidth={40}
          extendedWidth={EXTENDED_WIDTH}
          secondary
          leftIcon={<MdIcon name="Menu" color="inherit" mr={-2} />}
          minH="0px"
          name="Menu"
          overflow="visible"
        >
          <Flex
            backgroundColor="background.default"
            direction="column"
            width={{ base: '100%', md: EXTENDED_WIDTH }}
          >
            {/* TODO: Point this somewhere */}
            {/* <Link to={navRoutes.global.about.path()}>
          <JoinItem
            label={`About ${PLATFORM_DISPLAY_NAME}`}
            icon="Person"
          />
        </Link> */}
            {NAV_LINKS.map((l, idx) => (
              <Box key={`mob-nav-${l.label}`}>
                {idx !== 0 && (
                  <Divider borderColor="border.default" margin={0} />
                )}
                {l.dropdown ? (
                  <JoinItem
                    label={l.label}
                    icon={l.icon}
                    // @ts-ignore
                    isActive={currentRoute?.path() === l.link}
                    dropdown={l.dropdown}
                  />
                ) : (
                  <Box
                    {...(l.external
                      ? {
                          href: l.link,
                          // @ts-ignore
                          as: ExternalLink,
                          _hover: { textDecoration: 'none' },
                        }
                      : {
                          to: l.link || '#',
                          // @ts-ignore
                          as: Link,
                        })}
                  >
                    <JoinItem
                      label={l.label}
                      icon={l.icon}
                      // @ts-ignore
                      isActive={currentRoute?.path() === l.link}
                      // {...(l.onClick ? { onClick: l.onClick } : {})}
                    />
                  </Box>
                )}
              </Box>
            ))}
            {currentRoute?.path() !== navRoutes.global.planner.path() ? (
              <>
                <Divider borderColor="border.default" margin={0} />
                <LinkButton
                  m={3}
                  size="sm"
                  to={navRoutes.global.planner.path()}
                  icon="ArrowForward"
                  iconPosition="right"
                >
                  Request Free Trial
                </LinkButton>
              </>
            ) : null}
          </Flex>
        </AnimatedDropDown>
      </Flex>
    </Flex>
  );
};

export default JoinButton;
