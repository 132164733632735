import React from 'react';

import { RouteComponentProps } from 'react-router';

import { ScreenWrapper } from 'screens/common/ScreenWrapper';

import {
  Card,
  Flex,
  Text,
  Box,
  MdIcon,
  Button,
  Stack,
  chakra,
} from '@workshop/ui';

import { SteppitWavelengthLogo } from 'components/Brand';

interface WavelengthScreenProps extends RouteComponentProps {}

const WavelengthScreen: React.FC<WavelengthScreenProps> = ({ history }) => {
  const features = [
    { icon: 'FollowTheSigns', label: 'Self-paced sessions' },
    { icon: 'Groups3', label: 'Personalised pathways' },
    { icon: 'Gesture', label: 'Made for active learning' },
    { icon: 'Psychology', label: 'Perfect for pupils with SEN' },
  ];
  return (
    <ScreenWrapper>
      <Card padding={0} maxW="700px" mx="auto" mb={12}>
        <Box>
          <Flex
            justifyContent="center"
            alignItems="center"
            py={12}
            bg="blue.700"
          >
            <SteppitWavelengthLogo />
          </Flex>
          <Box py={8} px={{ base: 4, md: 8 }} textAlign="center">
            <Text
              fontSize="2xl"
              fontWeight="extrabold"
              mb={6}
              maxW="450px"
              mx="auto"
            >
              We Want to Give Every Learner an Experience That{' '}
              <chakra.span color="common.primary">
                Resonates with Them
              </chakra.span>
            </Text>
            <Text mb={8}>
              We're fusing Steppit’s no-overwhelm, active-learning formats with
              the expertise of specialised educators around the UK to build{' '}
              <chakra.b>Steppit Wavelength</chakra.b>: our new initiative with
              the goal of giving every student access to a personalised,
              step-by-step learning experience that perfectly fits their own
              unique needs and interests.
            </Text>
            <Stack
              direction={{ base: 'column', sm: 'row' }}
              spacing={{ base: 4, sm: 2, md: 4 }}
              mb={12}
            >
              {features.map(({ icon, label }) => (
                <Flex
                  key={`feature-${label}`}
                  flex={1}
                  flexDirection="column"
                  alignItems="center"
                >
                  <MdIcon
                    name={icon}
                    color="icon.primary"
                    boxSize={12}
                    mb={2}
                  />
                  <Text color="text.primary" fontWeight="semibold">
                    {label}
                  </Text>
                </Flex>
              ))}
            </Stack>
            <Button size="lg" mb={4} disabled>
              Full Website Coming Soon...
            </Button>
          </Box>
        </Box>
      </Card>
    </ScreenWrapper>
  );
};

export default WavelengthScreen;
