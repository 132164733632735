// --- Uncomment the desired environment --- //
// export const ENV = 'local';
// export const ENV = 'dev';
export const ENV = 'prod';

type Env = 'local' | 'dev' | 'prod';

type Platform = 'steppit' | 'workshop';

export const LOCAL_PLATFORM: Platform = 'steppit';
// export const LOCAL_PLATFORM: Platform = 'workshop';

export const PLATFORM = process.env.REACT_APP_PLATFORM
  ? (process.env.REACT_APP_PLATFORM as Platform)
  : LOCAL_PLATFORM;

export const BACKEND_ENV = process.env.REACT_APP_BACKEND_ENV
  ? (process.env.REACT_APP_BACKEND_ENV as Env)
  : ENV;

// --- This is the root file for all API calls --- //

export const urlMap = {
  // URL for local development
  local: {
    // baseUrl: 'http://100.96.1.18:8000',
    baseUrl: 'http://localhost:8000',
    // baseUrl: 'http://192.168.1.240:8000',
    discourseUrl: 'http://localhost:9292',
  },
  // URL for development
  dev: {
    baseUrl:
      PLATFORM === 'steppit'
        ? 'https://api-dev.steppit.com'
        : 'https://app-dev.workshop.ws',
    discourseUrl: 'https://discourse-dev.workshop.ws',
  },
  // URL for production
  prod: {
    baseUrl:
      PLATFORM === 'steppit'
        ? 'https://api.steppit.com'
        : 'https://app.workshop.ws',
    discourseUrl: 'https://community.workshop.ws',
  },
};

/** Max number of requests allowed in parallel via discourse API */
export const MAX_DISCOURSE_REQUESTS = 5;

// Default API prefix
// @ts-ignore
export const baseUrl = urlMap[BACKEND_ENV].baseUrl;
export const api = baseUrl + '/api/v1.1';
export const discourseUrl = urlMap[BACKEND_ENV].discourseUrl;
export const helpUrl =
  PLATFORM === 'workshop'
    ? 'https://help.workshop.ws/'
    : 'https://www.steppit.com/resources';
export const blogUrl = 'https://www.steppit.com/resources/blog';
export const aboutUrl = 'https://www.steppit.com/resources/about';
export const pricingUrl = 'https://www.steppit.com/resources/pricing';
export const affiliatesUrl = 'https://www.steppit.com/resources/affiliates';
export const contactUrl = 'https://www.steppit.com/resources/contact';
export const meetingUrl = 'https://www.steppit.com/resources/book-a-meeting';
export const trialUrl = 'https://www.steppit.com/resources/free-trial';
export const wavelengthUrl = 'https://wavelength.steppit.com/';

export const discordUrl = 'https://discord.gg/hyBhApmxeB';
export const aiFeatureUrl =
  'https://www.steppit.com/resources/features/ai-course-creator';
export const solutionUrls = {
  creators: 'https://www.steppit.com/resources/solutions/content-creators',
  coaches: 'https://www.steppit.com/resources/solutions/coaches',
  educators: 'https://www.steppit.com/resources/solutions/educators',
  brands: 'https://www.steppit.com/resources/solutions/brands',
  training: 'https://www.steppit.com/resources/solutions/internal-training',
  teachers: 'https://www.steppit.com/resources/solutions/teachers',
  providers: 'https://www.steppit.com/resources/solutions/providers',
  specialists: 'https://www.steppit.com/resources/solutions/specialists',
};
export const roadmapUrl = 'https://www.steppit.com/roadmap';

// Public API Keys
export const stripePublicKey: string =
  process.env.REACT_APP_STRIPE_PUBLIC_KEY || 'pk_test_XWqTYpXSKSFUq9USPsYNubsu';

// Full production mode
export const FRONT_ENV =
  process.env.NODE_ENV === 'production' && BACKEND_ENV === 'prod'
    ? 'production'
    : 'development';
