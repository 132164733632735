import React, { useState } from 'react';

import navRoutes from 'navigation/Routes';

import {
  helpUrl,
  blogUrl,
  aiFeatureUrl,
  aboutUrl,
  pricingUrl,
  affiliatesUrl,
  contactUrl,
  meetingUrl,
  trialUrl,
  solutionUrls,
  roadmapUrl,
} from 'constants/env';

import {
  Link as ExternalLink,
  Flex,
  Text,
  Box,
  Image,
  Stack,
  Container,
  SimpleGrid,
  MdIcon,
  useColorModeValue,
} from '@workshop/ui';
import { Link } from 'react-router-dom';

import Brand from 'components/Brand';

import { PaymentsWelcomeModal } from 'screens/cms/MyOrganisation/src';

import ukFlag from 'assets/images/illustrations/ukFlagSm.png';

interface FooterProps {}

const Footer: React.FC<FooterProps> = () => {
  const [showPricing, setShowPricing] = useState(false);
  const currentYear = new Date().getFullYear();
  const linkStyle = {
    display: 'inline-block',
    _hover: { textDecoration: 'underline' },
  };
  const activeColorMode =
    localStorage.getItem('chakra-ui-color-mode') || 'system';
  return (
    <Box
      bg={useColorModeValue('gray.50', 'gray.900')}
      color={useColorModeValue('gray.700', 'gray.200')}
      zIndex={1}
      as="footer"
    >
      <Container as={Stack} maxW="6xl" py={10}>
        <SimpleGrid
          columns={{ base: 1, sm: 2, md: 4 }}
          spacing={{ base: 8, sm: 6, md: 8 }}
          color="text.muted"
        >
          <Stack align={{ base: 'center', md: 'flex-start' }}>
            <Text fontWeight="semibold" mb={2}>
              Company
            </Text>
            <Box {...linkStyle}>
              <ExternalLink href={aboutUrl}>About Us</ExternalLink>
            </Box>
            <Box {...linkStyle}>
              <ExternalLink href={contactUrl}>Contact Us</ExternalLink>
            </Box>
            <Box {...linkStyle}>
              <ExternalLink href={meetingUrl}>Book an Intro</ExternalLink>
            </Box>
            <Box {...linkStyle}>
              <ExternalLink href={trialUrl}>Get a Free Trial</ExternalLink>
            </Box>
            <Box {...linkStyle}>
              <ExternalLink href={affiliatesUrl}>
                Become an Affiliate
              </ExternalLink>
            </Box>
            <Box {...linkStyle}>
              <ExternalLink
                href={roadmapUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                Product Roadmap
              </ExternalLink>
            </Box>
          </Stack>
          <Stack align={{ base: 'center', md: 'flex-start' }}>
            <Text fontWeight="semibold" mb={2}>
              Explore
            </Text>
            <Box {...linkStyle}>
              <Link to={navRoutes.global.planner.path()}>
                Plan a Course with AI
              </Link>
            </Box>
            <Box {...linkStyle}>
              <ExternalLink href={aiFeatureUrl}>About Our Pro AI</ExternalLink>
            </Box>
            <Box {...linkStyle}>
              <ExternalLink href={pricingUrl}>Pricing</ExternalLink>
            </Box>
            {/* <Box {...linkStyle}>
              <ExternalLink href={blogUrl}>Blog</ExternalLink>
            </Box> */}
          </Stack>
          <Stack align={{ base: 'center', md: 'flex-start' }}>
            <Text fontWeight="semibold" mb={2}>
              Solutions
            </Text>
            <Box {...linkStyle}>
              <ExternalLink href={solutionUrls.teachers}>
                Teachers, Tutors & Professors
              </ExternalLink>
            </Box>
            <Box {...linkStyle}>
              <ExternalLink href={solutionUrls.providers}>
                Independent Learning Providers
              </ExternalLink>
            </Box>
            <Box {...linkStyle}>
              <ExternalLink href={solutionUrls.specialists}>
                Education Specialists
              </ExternalLink>
            </Box>
            <Box {...linkStyle}>
              <ExternalLink href={solutionUrls.coaches}>Coaches</ExternalLink>
            </Box>
            <Box {...linkStyle}>
              <ExternalLink href={solutionUrls.creators}>
                Content Creators
              </ExternalLink>
            </Box>
            <Box {...linkStyle}>
              <ExternalLink href={solutionUrls.brands}>Brands</ExternalLink>
            </Box>
            <Box {...linkStyle}>
              <ExternalLink href={solutionUrls.training}>
                Internal Training
              </ExternalLink>
            </Box>
          </Stack>
          <Stack align={{ base: 'center', md: 'flex-start' }}>
            <Text fontWeight="semibold" mb={2}>
              Support
            </Text>
            <Box {...linkStyle}>
              <ExternalLink href={helpUrl}>Help & Support</ExternalLink>
            </Box>

            <Box {...linkStyle}>
              <Link to={navRoutes.global.privacy.path()}>Privacy Policy</Link>
            </Box>
            <Box {...linkStyle}>
              <Link to={navRoutes.global.cookies.path()}>Cookie Policy</Link>
            </Box>
            <Box {...linkStyle}>
              <Link to={navRoutes.global.terms.path()}>Terms of Service</Link>
            </Box>
          </Stack>
        </SimpleGrid>
      </Container>
      <Flex alignItems="center" justifyContent="center">
        <Flex
          padding={1}
          background="background.default"
          borderRadius="full"
          mt={3}
        >
          <Flex
            alignItems="center"
            paddingX={2}
            paddingY={1}
            cursor="pointer"
            bg={
              activeColorMode === 'light' ? 'background.tint3' : 'transparent'
            }
            _hover={{ bg: 'background.tint3' }}
            borderRadius="full"
            onClick={() => {
              localStorage.setItem('chakra-ui-color-mode', 'light');
              window.location.reload();
            }}
          >
            <MdIcon name="LightMode" color="icon.default" mr={2} />
            <Text fontSize="sm" fontWeight="semibold">
              Light
            </Text>
          </Flex>
          <Flex
            alignItems="center"
            paddingX={2}
            paddingY={1}
            cursor="pointer"
            bg={activeColorMode === 'dark' ? 'background.tint3' : 'transparent'}
            _hover={{ bg: 'background.tint3' }}
            borderRadius="full"
            mx={2}
            onClick={() => {
              localStorage.setItem('chakra-ui-color-mode', 'dark');
              window.location.reload();
            }}
          >
            <MdIcon name="DarkMode" color="icon.default" mr={2} />
            <Text fontSize="sm" fontWeight="semibold">
              Dark
            </Text>
          </Flex>
          <Flex
            alignItems="center"
            paddingX={2}
            paddingY={1}
            cursor="pointer"
            bg={
              activeColorMode === 'system' ? 'background.tint3' : 'transparent'
            }
            _hover={{ bg: 'background.tint3' }}
            borderRadius="full"
            onClick={() => {
              localStorage.removeItem('chakra-ui-color-mode');
              window.location.reload();
            }}
          >
            <MdIcon name="BrightnessMedium" color="icon.default" mr={2} />
            <Text fontSize="sm" fontWeight="semibold">
              Auto
            </Text>
          </Flex>
        </Flex>
      </Flex>
      <Box py={10}>
        <Flex
          align="center"
          _before={{
            content: '""',
            borderBottom: '1px solid',
            borderColor: useColorModeValue('gray.200', 'gray.700'),
            flexGrow: 1,
            mr: 8,
          }}
          _after={{
            content: '""',
            borderBottom: '1px solid',
            borderColor: useColorModeValue('gray.200', 'gray.700'),
            flexGrow: 1,
            ml: 8,
          }}
        >
          <Brand navbarTransparent={false} />
        </Flex>
        <Flex pt={6} alignItems="center" justifyContent="center">
          <Text fontSize="sm" textAlign="center">
            {`Made with `}
          </Text>
          <Image
            src={ukFlag}
            height="17.5px"
            width="20px"
            htmlHeight="17.5"
            htmlWidth="20"
            alignSelf="center"
            alt="❤️"
            mx={1}
          />
          <Text fontSize="sm" textAlign="center">
            {` in the UK`}
          </Text>
        </Flex>
        <Text pt={4} fontSize="sm" textAlign="center">
          {`©${currentYear} Workshop Tech Solutions Ltd.`}
        </Text>
      </Box>
      {showPricing && (
        <PaymentsWelcomeModal
          isOpen
          onClose={() => setShowPricing(false)}
          context="pricing"
          isConnected={false}
        />
      )}
    </Box>
  );
};

export default Footer;
