import React, { useEffect, useState } from 'react';
import { History } from 'history';
import * as Sentry from '@sentry/react';
import { useLocation, useHistory } from 'react-router';
import moment from 'moment';

import { FallbackError } from 'components/Error/FallbackError';
import { LoadingScreen } from 'components/Loading';
import CookieModal from 'components/CookieModal';

import InitPage from 'navigation/InitPage';
import {
  Router,
  Route as RouterRoute,
  Redirect,
  Switch,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { useToast } from '@workshop/ui';

import {
  authActions,
  profileActions,
  discourseActions,
  uiAction,
  organisationActions,
} from 'redux/actions/common';
import { cohortActions } from 'redux/actions/learner';
import {
  useIsAuthenticated,
  useCurrentTeam,
  useCurrentTeamProfile,
  useUser,
} from 'redux/selectors';
import { useDismissedInformationCards } from 'redux/selectors/user';
import { GlobalState } from 'types';
import messageBus from 'utils/messageBus';
import { hooks, analytics, getParamFromUrl } from 'utils';

import PrivateRoutes from './PrivateRoutes';
import TemplatedGlobalRoutes from './TemplatedGlobalRoutes';
import navRoutes from './Routes';

interface RoutesRenderer {
  isAuthenticated: boolean;
  isUserAccessLoading: boolean;
}

// Create Custom Sentry Route component to parameterized transactions
// @ts-ignore
const Route = Sentry.withSentryRouting(RouterRoute);

const RoutesRenderer: React.FC<RoutesRenderer> = ({
  isAuthenticated,
  isUserAccessLoading,
  ...rest
}) => {
  const [isSsoLoading, setIsSsoLoading] = useState(false);

  const location = useLocation();
  const history = useHistory();

  // Setup Impact affiliate tracking
  const impactClickId = getParamFromUrl(location, 'irclickid');
  // @ts-ignore
  if (impactClickId && !window.impactClickId) {
    // @ts-ignore
    window.impactClickId = impactClickId;
  }
  const gClickId = getParamFromUrl(location, 'gclid');
  const fbClickId = getParamFromUrl(location, 'fbclid');
  const ttClickId = getParamFromUrl(location, 'ttclid');
  const ssoToken = getParamFromUrl(location, 'sso');
  const selectedTeam = getParamFromUrl(location, 'team');
  const currentTeam = useCurrentTeam();
  const dispatch = useDispatch();
  const sid = localStorage.getItem('sid') || undefined;

  const fetchToken = async (token: string) => {
    setIsSsoLoading(true);
    await dispatch(authActions.tokenRequest({}, token));
    history.push(location);
    setIsSsoLoading(false);
  };

  useEffect(() => {
    if (ssoToken && !isAuthenticated) {
      fetchToken(ssoToken);
    }
  }, [ssoToken, isAuthenticated]);

  // When loaded in the app, make sure the team is set correctly
  useEffect(() => {
    if (
      isAuthenticated &&
      ssoToken &&
      selectedTeam &&
      selectedTeam !== `${currentTeam}`
    ) {
      dispatch(organisationActions.setCurrentTeam(Number(selectedTeam)));
      localStorage.setItem('defaultTeam', selectedTeam);
      window.location.reload();
    }
  }, [isAuthenticated, ssoToken, selectedTeam, currentTeam]);

  const logInboundClick = async (
    clickId: string,
    source: 'g' | 'fb' | 'tt'
  ) => {
    const res = await dispatch(
      profileActions.logInboundClick({
        slug: sid,
        click: { id: clickId, source: source, timestamp: Date.now() },
      })
    );
    const payload = res?.payload;
    if (
      payload &&
      'success' in payload &&
      payload.success &&
      'slug' in payload &&
      payload.slug
    ) {
      localStorage.setItem('sid', payload.slug);
    }
    analytics.track(
      `${
        source === 'g'
          ? 'Google '
          : source === 'fb'
          ? 'Facebook '
          : source === 'tt'
          ? 'TikTok '
          : ''
      }Ad Clicked`,
      { id: clickId }
    );
  };

  useEffect(() => {
    if (gClickId) {
      logInboundClick(gClickId, 'g');
    }
    if (fbClickId) {
      logInboundClick(fbClickId, 'fb');
    }
    if (ttClickId) {
      logInboundClick(ttClickId, 'tt');
    }
  }, [gClickId, fbClickId, ttClickId]);

  const mergeSidWithUser = async () => {
    const res = await dispatch(
      profileActions.logInboundClick({
        slug: sid,
      })
    );
    const payload = res?.payload;
    // @ts-ignore
    if (payload && 'success' in payload && payload.success && !payload.slug) {
      localStorage.removeItem('sid');
    }
  };

  useEffect(() => {
    if (isAuthenticated && sid) {
      mergeSidWithUser();
    }
  }, [isAuthenticated, sid]);

  if (isSsoLoading) return <LoadingScreen />;

  if (isAuthenticated) {
    // If logged in, wait until we know whether the user is a learner or
    // team member before deciding where to take them
    if (isUserAccessLoading) return <LoadingScreen />;
    const homepage = navRoutes.common.home.path();
    return (
      <Switch>
        {/* <Redirect exact from="/" to={homepage} /> */}
        {Object.keys(navRoutes.public)
          .filter((k) => {
            const route = navRoutes.public[k];
            return !route.noAuthOnly;
          })
          .map((k, idx) => {
            const route = navRoutes.public[k];
            const { path, redirect } = route;

            return (
              <Redirect
                key={`route-${path()}_${idx}`}
                from={path()}
                to={redirect || homepage}
              />
            );
          })}
        {Object.keys(navRoutes.global).map((k, idx) => {
          const route = navRoutes.global[k];
          const { path, component, useTemplate, redirect } = route;

          if (redirect) {
            return (
              <Route
                key={`route-${path()}_${idx}`}
                from={path()}
                component={() => {
                  window.location.replace(redirect);
                  return null;
                }}
              />
            );
          }
          return (
            <Route
              key={`route-${path()}_${idx}`}
              path={path()}
              exact
              {...(useTemplate
                ? {
                    render: () => <TemplatedGlobalRoutes />,
                    ...rest,
                  }
                : { component })}
            />
          );
        })}
        <Route exact render={() => <PrivateRoutes />} {...rest} />
      </Switch>
    );
  }

  return (
    <Switch>
      {Object.keys({ ...navRoutes.public, ...navRoutes.global }).map(
        (k, idx) => {
          const route = navRoutes.public[k] || navRoutes.global[k];
          const { path, component, useTemplate, redirect } = route;

          if (redirect) {
            return (
              <Route
                key={`route-${path()}_${idx}`}
                from={path()}
                component={() => {
                  window.location.replace(redirect);
                  return null;
                }}
              />
            );
          }
          return (
            <Route
              key={`route-${path()}_${idx}`}
              path={path()}
              exact
              {...(useTemplate
                ? {
                    render: () => <TemplatedGlobalRoutes />,
                    ...rest,
                  }
                : { component })}
            />
          );
        }
      )}
      <Redirect
        to={`${navRoutes.public.login.path()}?redirect=${location.pathname}`}
      />
    </Switch>
  );
};

const AppRouter: React.FC<{ history: History<unknown> }> = ({ history }) => {
  const dispatch = useDispatch();
  const isAuthenticated = useIsAuthenticated();
  const user = useUser();

  const cookieChoice = localStorage.getItem('cookies');

  const [initAuthCompleted, setInitAuthCompleted] = useState(false);
  const [userDetailsRetrieved, setUserDetailsRetrieved] = useState(false);

  const [cookieModalOpen, setCookieModalOpen] = useState(!cookieChoice);
  const [windowScrolled, setWindowScrolled] = useState(false);

  const userAccessDataFetched = hooks.useUserAccess();
  const baseDataFetched = hooks.useLoadBaseData();

  const currentTeamProfile = useCurrentTeamProfile();
  const dismissedInformationCards = useDismissedInformationCards();

  // Initialise analytics on first load
  useEffect(() => {
    analytics.init();
  }, []);

  // Identify user for analytics
  useEffect(() => {
    if (user.id) {
      analytics.setUserId(user.id, user.email);
    } else {
      analytics.identifyAnonymousUser();
    }
  }, [user.id]);

  // When the application is first loaded, attempt to refresh the JWT
  // token, if one is present
  useEffect(() => {
    const initAuth = async () => {
      await dispatch(authActions.refreshTokenRequest());

      setInitAuthCompleted(true);
    };

    initAuth();
  }, [setInitAuthCompleted, dispatch]);

  // Once the JWT token has been refreshed (or failed to refresh), attempt
  // to retrieve the user's details. This will only run if the refresh token
  // request was successful and the user is marked as authenticated
  useEffect(() => {
    if (!initAuthCompleted) return;

    if (!isAuthenticated) {
      setUserDetailsRetrieved(true);
      return;
    }

    const fetchUserProfile = async () => {
      await dispatch(profileActions.fetchUserProfile());
      setUserDetailsRetrieved(true);

      dispatch(discourseActions.getNotifications());
    };

    fetchUserProfile().then(() => {
      /**
       * This is necessary to help with discourse data
       * TODO: Check this is needed after hooking discourse
       * data up to notifications in backend
       */
      dispatch(cohortActions.list({ fetchNextPage: true }));
    });
  }, [initAuthCompleted, isAuthenticated, dispatch]);

  useEffect(() => {
    // Log Pro purchase if trial was started over 7 days ago
    if (
      dismissedInformationCards.includes('proTrialStarted') &&
      currentTeamProfile?.isPro &&
      currentTeamProfile?.proStarted
    ) {
      const proTrialComplete = moment().isAfter(
        moment(currentTeamProfile.proStarted).add(7, 'days')
      );
      if (proTrialComplete) {
        const tier = currentTeamProfile.proPlan?.tier;
        analytics.logConversion(
          dispatch,
          'purchasedPro',
          {},
          `steppit-pro${tier ? `-${tier}` : ''}`
        );
        dispatch(
          profileActions.updateUserProfile(
            {
              dismissedInformationCards: dismissedInformationCards.filter(
                (ic) => ic !== 'proTrialStarted'
              ),
            },
            { toast: { success: false, error: false } }
          )
        );
      }
    }
  }, [
    dismissedInformationCards.includes('proTrialStarted'),
    currentTeamProfile?.proStarted,
  ]);

  // Discourse Init
  const discourseUserId = useSelector(
    (state: GlobalState) => state.user.discourseUser.user?.id
  );
  const userApiKey = useSelector(
    (state: GlobalState) => state.user.userDetails.discourseUserApiKey
  );

  // If the user is authenticated and we have both a Discourse
  // API key and Discourse user ID for them, initialise the message
  // bus with the user's details
  useEffect(() => {
    if (userApiKey && discourseUserId) {
      messageBus.init(userApiKey, discourseUserId, dispatch);
    }
  }, [userApiKey, discourseUserId]);

  // Toast Init
  const toastUI = useSelector((state: GlobalState) => state.ui.toast);
  const toast = useToast();
  // If the application toast UI state changes, trigger the display of
  // a toast in the correct format (success/error)
  useEffect(() => {
    if (toastUI.error) {
      if (!toast.isActive('toast-error')) {
        toast({
          id: 'toast-error',
          title: 'An error occurred.',
          description: toastUI.errorMessage || undefined,
          status: 'error',
          duration: 2000,
          isClosable: true,
        });
      }

      // The following timeout allows us to prevent the taost
      // error to display multiple times, e.g in case we get multiple
      // failures when fetching data for a single page
      setTimeout(() => {
        dispatch(uiAction.clearToastMessage);
      }, 4000);
    } else if (toastUI.success) {
      if (!toast.isActive('toast-success')) {
        toast({
          id: 'toast-success',
          title: toastUI.successMessage || 'Success',
          status: 'success',
          duration: 2000,
          isClosable: true,
        });
      }
      dispatch(uiAction.clearToastMessage);
    }
  }, [
    toast,
    toastUI.error,
    toastUI.errorMessage,
    toastUI.success,
    toastUI.successMessage,
  ]);

  const setWindowHasScrolled = () => {
    if (!windowScrolled) setWindowScrolled(true);
  };

  useEffect(() => {
    if (cookieModalOpen && !windowScrolled) {
      setTimeout(() => {
        window.onscroll = () => {
          setWindowHasScrolled();
        };
      }, 3000);
    }
  }, [cookieModalOpen, windowScrolled]);

  if (!initAuthCompleted || !userDetailsRetrieved || !baseDataFetched) {
    return <LoadingScreen />;
  }

  return (
    <Router history={history}>
      {/* @ts-ignore */}
      <Sentry.ErrorBoundary fallback={FallbackError} showDialog>
        <InitPage>
          <RoutesRenderer
            isAuthenticated={isAuthenticated}
            isUserAccessLoading={!userAccessDataFetched}
          />
          <CookieModal
            isOpen={cookieModalOpen}
            onClose={() => setCookieModalOpen(false)}
          />
        </InitPage>
      </Sentry.ErrorBoundary>
    </Router>
  );
};

export default AppRouter;
