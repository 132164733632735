import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { FaGem } from 'react-icons/fa';
import {
  Flex,
  Box,
  Grid,
  Text,
  HStack,
  Divider,
  List,
  ListIcon,
  ListItem,
  Button,
  LinkButton,
  useColorModeValue,
  chakra,
} from '@workshop/ui';
import { CheckCircleIcon } from '@chakra-ui/icons';

import { GlobalState } from 'types';

import { PRO_TIER_COLORS } from 'constants/organisation';
import { pricingUrl, meetingUrl } from 'constants/env';

import { BrandSelect } from 'components/Brand';

type Price = {
  name: string;
  popular?: boolean;
  info: string;
  features: string[];
  featuresIntro: string;
  tier: 0 | 1 | 2 | 3;
  payMonthlyPriceGBP?: string;
  payMonthlyPriceEUR?: string;
  payMonthlyPriceUSD?: string;
  payYearlyPriceGBP?: string;
  payYearlyPriceEUR?: string;
  payYearlyPriceUSD?: string;
};

const simplePrices: Price[] = [
  {
    name: 'Basic',
    featuresIntro: '',
    features: [
      'Create small courses and free sessions',
      'Up to 4 units on your courses',
      'Up to 6 sessions per unit',
      '80% revenue share of course sales made via Steppit',
      '1 channel team member',
      'Branded channel page & course landing pages',
      'Option to create free, publicly available content',
      'Cohort-based classes & choice between instant access and weekly unlocking content',
      'Private class feeds of posts & group chats',
    ],
    info: 'Create and share free sessions and small courses for your learners.',
    tier: 0,
  },
  {
    name: 'Pro',
    payMonthlyPriceGBP: '£30',
    payMonthlyPriceEUR: '€35',
    payMonthlyPriceUSD: '$30',
    payYearlyPriceGBP: '£25',
    payYearlyPriceEUR: '€29',
    payYearlyPriceUSD: '$25',
    featuresIntro: 'Plus all Basic features',
    features: [
      'Pro plans available for channels of all sizes (Starter, Growth & Scale)',
      'Fully personalized AI assistant, with up to 10k AI credits per month',
      'Free access to our Accelerator Program, run by the Steppit team',
      'Unlimited units on your courses',
      '80-90% revenue share of course sales made via Steppit',
      'Unlimited enrollments when learners purchase your courses via Steppit',
      'Up to 120 enrollments per month when learners come from invites or external integrations',
      'Up to 20 channel team members',
      'Integrate with 5,000+ apps via Zapier',
      'Embed your Steppit sessions on your website (available on our Growth & Scale plans)',
    ],
    info: 'Build and run courses yourself or as a team, and access everything that Steppit has to offer.',
    tier: 1,
    popular: true,
  },
  {
    name: 'Select',
    featuresIntro: '',
    features: [
      'One-to-one premium support from the Steppit team',
      'Fully serviced, bespoke solutions built around your needs',
      'Premium packages available for market analysis, course planning, end-to-end production and digital marketing consultancy',
      'Expert team of course creation specialists, here to take all the hassle away from course creation and make your life easier',
    ],
    info: 'Fast-track your business with premium packages and one-to-one support.',
    tier: 3,
  },
];

const prices: Price[] = [
  {
    name: 'Starter',
    payMonthlyPriceGBP: '£30',
    payMonthlyPriceEUR: '€35',
    payMonthlyPriceUSD: '$30',
    payYearlyPriceGBP: '£25',
    payYearlyPriceEUR: '€29',
    payYearlyPriceUSD: '$25',
    featuresIntro: 'Plus all Basic features',
    features: [
      'Unlimited units on your courses',
      '2,000 AI credits per month for your personalized AI assistant + 1,000 bonus credits on signup',
      'Free access to our Accelerator Program, run by the Steppit team',
      '80% revenue share of course sales made via Steppit',
      'Unlimited enrollments when learners purchase your courses via Steppit',
      'Up to 20 enrollments per month when learners come from invites or external integrations',
      'Up to 5 channel team members',
      'Integrate with 5,000+ apps via Zapier',
    ],
    info: 'Start building your perfect course with AI and privately enroll your first learners.',
    tier: 1,
  },
  {
    name: 'Growth',
    payMonthlyPriceGBP: '£80',
    payMonthlyPriceEUR: '€90',
    payMonthlyPriceUSD: '$80',
    payYearlyPriceGBP: '£67',
    payYearlyPriceEUR: '€75',
    payYearlyPriceUSD: '$67',
    featuresIntro: 'Plus all Starter plan features',
    features: [
      'Unlimited units on your courses',
      '5,000 AI credits per month for your personalized AI assistant + 1,000 bonus credits on signup',
      'Free access to our Accelerator Program, run by the Steppit team',
      '85% revenue share of course sales made via Steppit',
      'Unlimited enrollments when learners purchase your courses via Steppit',
      'Up to 60 enrollments per month when learners come from invites or external integrations',
      'Up to 10 channel team members',
      'Integrate with 5,000+ apps via Zapier',
      'Embed your Steppit sessions on your website',
      'High-priority customer support',
    ],
    info: 'Generate more content and expand your reach with higher caps and the option to embed.',
    tier: 2,
    popular: true,
  },
  {
    name: 'Scale',
    payMonthlyPriceGBP: '£140',
    payMonthlyPriceEUR: '€160',
    payMonthlyPriceUSD: '$140',
    payYearlyPriceGBP: '£117',
    payYearlyPriceEUR: '€134',
    payYearlyPriceUSD: '$117',
    featuresIntro: 'Plus all Growth plan features',
    features: [
      'Unlimited units on your courses',
      '10,000 AI credits per month for your personalized AI assistant + 1,000 bonus credits on signup',
      'Free access to our Accelerator Program, run by the Steppit team',
      '90% revenue share of course sales made via Steppit',
      'Unlimited enrollments when learners purchase your courses via Steppit',
      'Up to 120 enrollments per month when learners come from invites or external integrations',
      'Up to 20 channel team members',
      'Integrate with 5,000+ apps via Zapier',
      'Embed your Steppit sessions on your website',
      'Dedicated account manager, custom onboarding & production support',
    ],
    info: 'Maximize your income and scale up your services with added support and flexibility.',
    tier: 3,
  },
];

interface PricingBoxProps extends Price {
  planType: 'monthly' | 'yearly';
  proTrialAvailable?: boolean;
  onClickCta?: (
    planType: 'monthly' | 'yearly',
    tier: 0 | 1 | 2 | 3
  ) => Promise<void>;
  simple?: boolean;
}

const PricingBox = ({
  popular,
  name,
  info = '',
  features = [],
  featuresIntro = '',
  tier = 0,
  payMonthlyPriceGBP,
  payMonthlyPriceEUR,
  payMonthlyPriceUSD,
  payYearlyPriceGBP,
  payYearlyPriceEUR,
  payYearlyPriceUSD,
  planType,
  proTrialAvailable,
  onClickCta,
  simple,
}: PricingBoxProps) => {
  const tierColor = PRO_TIER_COLORS[tier];
  const mainTierColor = useColorModeValue(
    `${tierColor}.550`,
    `${tierColor}.200`
  );
  const bgTierColor = useColorModeValue(`${tierColor}.50`, `${tierColor}.700`);
  const borderTierColor = useColorModeValue(
    `${tierColor}.100`,
    `${tierColor}.200`
  );

  const location = useSelector(
    (state: GlobalState) => state.background.location
  );

  const currency = location?.currency || 'USD';

  const payMonthlyPrice =
    currency === 'GBP'
      ? payMonthlyPriceGBP
      : currency === 'EUR'
      ? payMonthlyPriceEUR
      : payMonthlyPriceUSD;

  const payYearlyPrice =
    currency === 'GBP'
      ? payYearlyPriceGBP
      : currency === 'EUR'
      ? payYearlyPriceEUR
      : payYearlyPriceUSD;

  const price = planType === 'monthly' ? payMonthlyPrice : payYearlyPrice;
  return (
    <Flex
      flexDirection="column"
      p={4}
      position="relative"
      borderWidth={1}
      {...(popular
        ? {
            bg: 'background.tint1',
            borderColor: 'border.muted',
            borderRadius: 'lg',
          }
        : {
            borderColor: 'transparent',
          })}
    >
      {popular && !simple && (
        <Box
          position="absolute"
          top={4}
          right={4}
          paddingX={2}
          paddingY={1}
          bg="background.tint2"
          color="text.muted"
          fontSize="xs"
          borderRadius="full"
        >
          <Text>Most popular</Text>
        </Box>
      )}
      <Box mb={3}>
        {!simple && (
          <Flex
            boxSize="image.xs"
            alignItems="center"
            justifyContent="center"
            borderRadius="full"
            bg={bgTierColor}
            color={mainTierColor}
            fontSize="xl"
          >
            <FaGem />
          </Flex>
        )}
      </Box>
      {simple && tier > 1 ? (
        <Box mb={6} mt={1}>
          <BrandSelect onBg />
        </Box>
      ) : (
        <>
          <Text
            color={mainTierColor}
            fontSize={simple ? '4xl' : '3xl'}
            fontWeight={simple ? 'extrabold' : 'bold'}
            mb={2}
          >
            {name}
          </Text>
        </>
      )}

      <Text fontSize="sm" color="text.muted" mb={4}>
        {info}
      </Text>
      {simple ? (
        <Text fontSize="3xl" fontWeight="bold" mb={2}>
          {price ? (
            <>
              <chakra.span color="text.muted" fontSize="sm" fontWeight="normal">
                From
              </chakra.span>
              {` ${price}`}
              <chakra.span color="text.muted" fontSize="sm" fontWeight="normal">
                /month
              </chakra.span>
            </>
          ) : tier > 0 ? (
            'Contact Us'
          ) : (
            'Free'
          )}
        </Text>
      ) : (
        <HStack mb={2}>
          <Text fontSize="4xl" fontWeight="semibold">
            {price ?? 'Free'}
          </Text>
          {price && (
            <Box
              as="span"
              color="text.muted"
              fontSize="sm"
              whiteSpace="break-spaces"
              lineHeight={1.1}
            >
              {`per\nmonth`}
            </Box>
          )}
        </HStack>
      )}

      {planType === 'yearly' && !simple && (
        <Box
          as="span"
          color="text.muted"
          fontSize="xs"
          whiteSpace="break-spaces"
          lineHeight={1.1}
          mb={2}
          mt={-1}
        >
          {`Billed yearly`}
        </Box>
      )}

      <Divider borderColor={borderTierColor} mb={6} />
      <List flex={1} mb={12}>
        {features.map((feat) => (
          <ListItem
            key={Math.random()}
            fontSize="sm"
            color="text.muted"
            pb={1}
            display="flex"
          >
            <ListIcon as={CheckCircleIcon} color="common.progress" mt={1} />
            <Text>{feat}</Text>
          </ListItem>
        ))}
        <Text
          color="text.muted"
          fontSize="sm"
          fontWeight="semibold"
          mt={2}
          mb={2}
        >
          {featuresIntro}
        </Text>
      </List>
      {onClickCta && !simple ? (
        <Button
          variant={popular ? 'solid' : 'outline'}
          onClick={() => onClickCta(planType, tier)}
        >
          {proTrialAvailable ? 'Start 7-day Free Trial' : 'Select Plan'}
        </Button>
      ) : simple ? (
        <>
          {tier === 1 && (
            <LinkButton
              mb={2}
              href="/plan?p=pro"
              icon="ArrowForward"
              iconPosition="right"
            >
              Try Pro for Free
            </LinkButton>
          )}
          <LinkButton
            variant="outline"
            href={tier === 0 ? '/plan' : tier === 1 ? pricingUrl : meetingUrl}
          >
            {tier === 0
              ? 'Start Now'
              : tier === 1
              ? 'Explore Plans'
              : 'Book an Intro'}
          </LinkButton>
        </>
      ) : null}
    </Flex>
  );
};

const PricingTable: React.FC<{
  proTrialAvailable?: boolean;
  onClickCta?: (
    planType: 'monthly' | 'yearly',
    tier: 0 | 1 | 2 | 3
  ) => Promise<void>;
  simple?: boolean;
}> = ({ proTrialAvailable, onClickCta, simple }) => {
  const [planType, setPlanType] = useState<'monthly' | 'yearly'>(
    simple ? 'yearly' : 'monthly'
  );
  const tablePrices = simple ? simplePrices : prices;
  return (
    <Flex direction="column" alignItems="center" justifyContent="center">
      {!simple && (
        <HStack
          spacing={1}
          border="1px solid"
          borderColor="border.muted"
          bg="background.tint1"
          borderRadius="full"
          mb={6}
          p={1}
        >
          <Button
            variant={planType === 'monthly' ? 'solid' : 'ghost'}
            size="sm"
            onClick={() => setPlanType('monthly')}
          >
            Monthly
          </Button>
          <Button
            variant={planType === 'yearly' ? 'solid' : 'ghost'}
            size="sm"
            onClick={() => setPlanType('yearly')}
          >
            Yearly
          </Button>
        </HStack>
      )}
      <Grid
        w="full"
        gap={5}
        justifyContent="center"
        templateColumns={{
          base: 'inherit',
          md: 'repeat(3, 1fr)',
        }}
      >
        {tablePrices.map((price) => (
          <PricingBox
            key={price.name}
            planType={planType}
            proTrialAvailable={proTrialAvailable}
            onClickCta={onClickCta}
            simple={simple}
            {...price}
          />
        ))}
      </Grid>
    </Flex>
  );
};

export default PricingTable;
