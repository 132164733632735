import React, { useEffect } from 'react';

import { RouteComponentProps } from 'react-router';

import { ScreenWrapper } from 'screens/common/ScreenWrapper';

import { analytics } from 'utils';

import { Flex, Text, Heading, Box, LinkButton, Stack } from '@workshop/ui';

import {
  SteppitWavelengthHorizLogo,
  BrandLg,
  SteppitMiniIcon,
} from 'components/Brand';

interface JoinScreenProps extends RouteComponentProps {}

const JoinScreen: React.FC<JoinScreenProps> = ({ history }) => {
  useEffect(() => {
    analytics.track('Join Page Visited');
  }, []);
  const options = [
    {
      bgColor: 'green.400',
      mainColor: 'green.800',
      logoBg: '#fff',
      logo: <BrandLg />,
      logoPx: 6,
      title: 'Discover a new way of teaching online',
      description:
        'Find out how you could deliver unparalled online learning experiences with Steppit, and book an intro to discuss how we can help with your online learning provision.',
      primaryCtaLabel: 'Book an Intro',
      primaryCtaLink: 'https://www.steppit.com/resources/book-a-meeting/',
      primaryHoverColor: 'green.700',
      secondaryCtaLabel: 'Learn More',
      secondaryCtaLink: 'https://www.steppit.com/',
      secondaryHoverColor: 'green.300',
      secondaryClickColor: 'green.200',
    },
    {
      bgColor: 'blue.400',
      mainColor: 'blue.800',
      logoBg: 'blue.800',
      logo: <SteppitWavelengthHorizLogo />,
      logoPx: 4,
      title: 'Give every student an experience that suits their needs',
      description:
        'Join our mission to create personalised, step-by-step pathways to help every UK student learn the national curriculum on their wavelength.',
      primaryCtaLabel: 'Register Interest',
      primaryCtaLink: 'https://wavelength.steppit.com/join',
      primaryHoverColor: 'blue.700',
      secondaryCtaLabel: 'Learn More',
      secondaryCtaLink: 'https://wavelength.steppit.com/',
      secondaryHoverColor: 'blue.300',
      secondaryClickColor: 'blue.200',
    },
  ];
  return (
    <Box bg="background.default" py={{ base: 12, md: 20 }} minHeight="100vh">
      <ScreenWrapper>
        <Box mx="auto" mb={6}>
          <SteppitMiniIcon color="#2ddd8f" size="lg" />
        </Box>
        <Box textAlign="center" px={{ base: 4, sm: 6, md: 0 }}>
          <Heading
            as="h1"
            lineHeight={1.2}
            fontWeight="extrabold"
            fontSize={{
              base: '3xl',
              md: '5xl',
              lg: '5xl',
              '2xl': '6xl',
            }}
            pb={6}
          >
            Welcome to Steppit
          </Heading>
          <Text
            color="text.muted"
            fontSize={{ base: 'md', md: 'xl' }}
            pb={12}
            maxW={{ base: '400px', md: '500px' }}
            mx="auto"
          >
            To get in touch or explore our plans and projects, check out the
            links below:
          </Text>
        </Box>
        <Stack
          direction={{ base: 'column', md: 'row' }}
          spacing={6}
          px={{ base: 4, sm: 6, md: 0 }}
          alignItems={{ base: 'center', md: 'inherit' }}
          justifyContent="center"
        >
          {options.map((o) => (
            <Flex
              borderRadius="lg"
              py={8}
              bg={o.bgColor}
              color={o.mainColor}
              maxWidth="500px"
              boxShadow="xl"
              flex={1}
              flexDirection="column"
            >
              <Flex
                bg={o.logoBg}
                borderLeftRadius="full"
                py={3}
                px={o.logoPx}
                h={20}
                mb={6}
                ml={{ base: 6, md: 8 }}
                alignItems="center"
              >
                {o.logo}
              </Flex>
              <Flex px={{ base: 6, md: 8 }} flexDirection="column" flex={1}>
                <Heading
                  as="h2"
                  lineHeight={1.2}
                  fontWeight="extrabold"
                  fontSize={{
                    base: '2xl',
                  }}
                  mb={3}
                  maxWidth="350px"
                >
                  {o.title}
                </Heading>
                <Text mb={6} flex={1}>
                  {o.description}
                </Text>
                <Stack
                  direction={{
                    base: 'column',
                    sm: 'row',
                    md: 'column',
                    lg: 'row',
                  }}
                  alignItems="flex-start"
                >
                  <LinkButton
                    href={o.primaryCtaLink}
                    bg={o.mainColor}
                    color="#fff"
                    _hover={{
                      bg: o.primaryHoverColor,
                    }}
                    _active={{
                      bg: o.mainColor,
                    }}
                  >
                    {o.primaryCtaLabel}
                  </LinkButton>
                  <LinkButton
                    variant="outline"
                    href={o.secondaryCtaLink}
                    color={o.mainColor}
                    borderColor={o.mainColor}
                    _hover={{
                      bg: o.secondaryHoverColor,
                    }}
                    _active={{
                      bg: o.secondaryClickColor,
                    }}
                  >
                    {o.secondaryCtaLabel}
                  </LinkButton>
                </Stack>
              </Flex>
            </Flex>
          ))}
        </Stack>
      </ScreenWrapper>
    </Box>
  );
};

export default JoinScreen;
